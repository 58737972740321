import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"
const About = () => {
  const data = useStaticQuery(graphql`
    query MyQuery7 {
      allStrapiAboutFirstmain {
        edges {
          node {
            service {
              title
              description
              image {
                publicURL
              }
            }
            aboutSummary {
              summaryDescription
              summaryTitle
            }
            founding {
              description
              image {
                publicURL
              }
              title
              author
            }
            bannerImage {
              bannerImg {
                publicURL
              }
            }
            getInTouch {
              description
            }
          }
        }
      }
    }
  `)

  const {
    service,
    aboutSummary,
    founding,
    bannerImage,
    getInTouch,
  } = data.allStrapiAboutFirstmain.edges[0].node

  return (
    <StyledWrapper>
      <Layout>
        <SEO
          title="About"
          description="Through human centered design and technology, we deliver immersive digital product experiences
                        for the world."
          path="/about"
        />
        <div className="container about-container">
          <div className="row">
            <div className="col-12">
              <div className="summary-section d-flex justify-content-center flex-column">
                <div>
                  <p className="color-muddy summary-title">
                    {aboutSummary.summaryTitle}
                  </p>
                  <p className="summary-description">
                    {aboutSummary.summaryDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid background-brown px-sm-0 px-3">
          <div className="row">
            <div className="col-sm-6 col-12 d-flex justify-content-center flex-column">
              <div className="mx-auto py-sm-0 py-3">
                <p className="service-title">{service.title}</p>
                <p className="service-description color-white pre-line">
                  {service.description}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-12">
              <img className="img-fluid" src={service.image.publicURL} alt="" />
            </div>
          </div>
        </div>
        <div className="container mtop">
          <div className="row">
            <div className="col-sm-8 col-12 d-flex justify-content-center flex-column">
              <div className="mx-auto">
                <p className="founding-title">{founding.title}</p>
                <ReactMarkdown className="founding-description pre-line">
                  {founding.description}
                </ReactMarkdown>
                <p className="founding-description color-purple">
                  {founding.author}
                </p>
              </div>
            </div>
            <div className="col-sm-4 col-12">
              <img
                className="img-fluid"
                src={founding.image.publicURL}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container-fluid px-sm-0 px-3 mtop">
          <div className="row">
            <div className="col-12">
              <img
                className="img-fluid"
                src={bannerImage.bannerImg.publicURL}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ReactMarkdown className="get-in-touch">
                {getInTouch.description}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </Layout>
    </StyledWrapper>
  )
}

export default About

const StyledWrapper = styled.div`
  .about-container{
    padding:100px 20px 50px;
  }

  .mtop {
    margin-top: 100px;
  }

  .summary-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.065em;
  }

  .summary-description {
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
  }

  .service-description {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .service-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    color: #c2c9d1;
  }

  .background-brown {
    background-color: #5d574a;
  }

  .founding-title {
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
  }

  .founding-description * {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  .get-in-touch p,
  a {
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
  }

  .get-in-touch a::before {
    background-color: white !important;
  }

  .get-in-touch a {
    text-transform: capitalize;
    color: #9b51e0;
  }

  .get-in-touch a:hover {
    color: #c15f6d;
  }

  .get-in-touch {
    margin:100px 0px;
  }

  @media(min-width:991px){
    .about-container {
      padding: 150px 0px;
    }

    .summary-description {
      font-size: 48px;
      line-height: 65px;
    }

    .service-description{
      font-size:36px;
      line-height:46px;
    }

    .get-in-touch p,
    a {
      font-size: 48px;
      font-weight: 400;
      line-height: 65px;
    }
    
    .get-in-touch {
      margin:200px 0px;
    }
  }
`
